import React from 'react'

import { WithoutAuthenticationLogin } from '~/components/Account/UnLogged'

import getAuthenticationPageStaticPropsFunction from '~/data/authentication-page-data'
import { AuthenticationPageProps } from '~/data/authentication-page-data/serializer'

function LoginPage(props: AuthenticationPageProps) {
  const { backgroundImage } = props

  return <WithoutAuthenticationLogin backgroundImage={backgroundImage} />
}

export default LoginPage

export const getStaticProps = getAuthenticationPageStaticPropsFunction({
  type: 'login_page',
})
